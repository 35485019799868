.welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .welcome-title {
    margin-bottom: 20px;
    color:white
  }
  
  .file-content {
    width: 80%;
    max-width: 1000px;
    height: 60vh;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .file-content pre {
    white-space: pre-wrap; /* Wrap long lines */
    word-wrap: break-word; /* Break long words */
  }
  
  .logout-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .logout-button:hover {
    background-color: #0056b3;
  }
  
  .logout-button.small {
    padding: 5px 10px;
    font-size: 0.9em;
  }

  .welcome-container {
    color: white;
  }
  
  .welcome-title {
    color: white;
  }
  
  .toggles-container {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    margin-bottom: 30px; /* Add space below the toggle container */
  }
  
  .toggle {
    display: flex;
    align-items: center;
    position: relative;
    
  }
  
  .toggle label.toggle-switch {
    display: inline-block;
    width: 50px;
    height: 25px;
    background-color: #ccc;
    border-radius: 25px;
    position: relative;
    transition: background-color 0.3s;
    cursor: pointer;
  }
  
  .toggle label.toggle-switch::after {
    content: '';
    display: inline-block;
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s;
  }
  
  .toggle input[type="checkbox"] {
    display: none;
  }
  
  .toggle input[type="checkbox"]:checked + label.toggle-switch {
    background-color: #4caf50;
  }
  
  .toggle input[type="checkbox"]:checked + label.toggle-switch::after {
    transform: translateX(25px);
  }
  
  .toggle span {
    margin-right: 10px;
    font-weight: bold;
    font-size: 18px;
    color: white;
  }
  
  .file-content {
    color: black;
  }

  /* Container for the picklists */
.picklists-container {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between the two picklists */
  /*margin-top: 20px;*/
  margin-bottom: 20px;
}

/* Style for picklist labels */
.picklist-label {
  font-weight: bold;
  margin-right: 10px; /* Space between the label and the select box */
  color: #f8f6f6;
  margin-left: 10px;
}

/* Custom select style */
.custom-select {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  font-size: 16px;
  color: #333;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.custom-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Optional: add a hover effect */
.custom-select:hover {
  border-color: #007bff;
}

/* Style the options */
.custom-select option {
  padding: 10px;
  background-color: #fff;
  color: #333;
}

/* Style for disabled state */
.custom-select:disabled {
  background-color: #e9ecef;
  color: #6c757d;
}
