body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #0B122C; /* Changed background color to a light blue */
  }
  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #0B122C; /* Ensure background color matches the body */
  }
  
  .login-box {
    background: white;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    width: 450px; /* Slightly increased width */
    max-width: 100%;
    text-align: left; /* Center align text in the box */
  }
  
  .login-title {
    font-size: 28px; /* Increased font size for the title */
    color: #02165c; /* Darker color for the title */
    margin-bottom: 20px; /* Space between title and form */
  }
  
  .login-form {
    margin-top: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  .error-message{
    color: red;
  }
  button {
    width: 100%;
    padding: 15px;
    background-color: #0B122C;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
  }
  
  button:hover {
    background-color: #02165c;
  }
  